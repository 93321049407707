<template>
  <div class="login">
    <div class="logo-container">
      <DigitalPassport class="digital-logo" />
    </div>
    <div class="button-container">
      <PassportButton label="sign in" @click="$router.push('/login')" class="login-button" />
      <PassportButton variant="outline" label="get started" @click="$router.push('/register')" class="started-button" />
    </div>
  </div>
</template>

<script>
// import { ApiService } from '@/services/api.service';
import PassportButton from '@/components/PassportButton.vue';
import DigitalPassport from '@/assets/images/logo_digital_passport.svg';

export default {
  name: 'MainPage',
  components: {
    // ApiService,
    PassportButton,
    DigitalPassport,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-logo {
  width: 3.875em;
  height: 1.875em;
}
.digital-logo {
  width: 14.5em;
  margin-top: 40px;
  margin-bottom: 40px;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .login-button {
    width: 15em;
    height: 3em;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $primary-white;
  }
  .started-button {
    width: 15em;
    height: 3em;
    margin-top: 0.5em;
    font-size: 20px;
    text-transform: uppercase;
  }
}
.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
